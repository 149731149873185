import removeDuplicates from 'utilities/removeDuplicates';

function handleProductsByFilter(
  selectedFilters,
  filteredProducts,
  first,
  ...rest
) {
  let products = [];
  for (let key of Object.keys(selectedFilters[first])) {
    products = filteredProducts[key]
      ? [...products, ...filteredProducts[key]]
      : [...products];
  }
  products = removeDuplicates(products, 'id');
  rest.forEach((arg) => {
    for (let key of Object.keys(selectedFilters[arg])) {
      products = products.filter(
        (product) => product[arg] && product[arg].title === key
      );
    }
  });
  return products;
}

const hasChangedPrice = (price, min, max) => {
  return price[0] !== min || price[1] !== max;
};

const initialFilters = {
  material: {},
  kind: {},
};

const hasSelectedFilters = (filters) => {
  return Object.keys(filters).some(
    (key) => Object.keys(filters[key]).length > 0
  );
};

const handleFilters = (currentFilters, newFilter, type) => {
  if (currentFilters[type][newFilter]) {
    let { [newFilter]: omit, ...newFilters } = currentFilters[type];
    return { ...currentFilters, [type]: { ...newFilters } };
  } else {
    return {
      ...currentFilters,
      [type]: { ...currentFilters[type], [newFilter]: true },
    };
  }
};

const promoSortByIndex = (products) =>
  products.sort((a, b) => {
    var aHas = a.hasOwnProperty('promoCategoryListIndex');
    var bHas = b.hasOwnProperty('promoCategoryListIndex');
    if (aHas && bHas) {
      return a.promoCategoryListIndex - b.promoCategoryListIndex;
    }
    return aHas ? -1 : bHas ? 1 : 0;
  });

const sortProducts = (sort, products) => {
  switch (sort) {
    case 'asc':
      return [...products.sort((a, b) => a.finalPrice - b.finalPrice)];
    case 'desc':
      return [...products.sort((a, b) => b.finalPrice - a.finalPrice)];
    default:
      // don't sort products
      // we are in a promo category, sort by promo index
      if (products && products[0] && products[0].promoCategory) {
        return [...promoSortByIndex(products)];
      }
      // regular category, sort by title
      return products;
  }
};

const getProductsByFilterAndPrice = (
  filteredProducts,
  allProducts,
  selectedFilters,
  price,
  min,
  max
) => {
  let tempProducts = [];
  if (Object.keys(selectedFilters.material).length > 0) {
    tempProducts = handleProductsByFilter(
      selectedFilters,
      filteredProducts,
      'material',
      'kind'
    );
  } else if (Object.keys(selectedFilters.kind).length > 0) {
    tempProducts = handleProductsByFilter(
      selectedFilters,
      filteredProducts,
      'kind',
      'material'
    );
  } else {
    tempProducts = [...allProducts];
  }
  if (hasChangedPrice(price, min, max)) {
    tempProducts = tempProducts.filter(
      (product) =>
        price[0] <= product.finalPrice && price[1] >= product.finalPrice
    );
  }
  return tempProducts;
};

const getPageCount = (dataLength, numberPerPage) =>
  Math.ceil(dataLength / numberPerPage);

const getOffset = (dataLength, currentOffset, numberPerPage, pageCount) =>
  dataLength <= currentOffset
    ? Math.ceil((pageCount - 1) * numberPerPage)
    : currentOffset;

const handleProducts = (
  filteredProducts,
  allProducts,
  selectedFilters,
  price,
  min,
  max,
  sort
) => {
  let products = getProductsByFilterAndPrice(
    filteredProducts,
    allProducts,
    selectedFilters,
    price,
    min,
    max
  );
  return sortProducts(sort, products);
};

export {
  handleProductsByFilter,
  handleProducts,
  handleFilters,
  hasChangedPrice,
  hasSelectedFilters,
  initialFilters,
  sortProducts,
  getPageCount,
  getOffset,
};
