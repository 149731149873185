import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import * as styles from './ContentSidebar.module.scss';

const ContentSidebar = ({ sidebar, icon, content }) => {
  const [isOpen, setStatus] = useState(false);
  return (
    <main className={styles.container}>
      <aside className={`${styles.sidebar} ${isOpen && styles.isOpen}`}>
        {icon ? (
          <button
            type="button"
            onClick={() => setStatus(!isOpen)}
            className={styles.handle}
          >
            {icon}
          </button>
        ) : (
          <button
            type="button"
            onClick={() => setStatus(!isOpen)}
            className={styles.handle}
          >
            {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
          </button>
        )}
        {sidebar}
      </aside>
      <section className={styles.content}>{content}</section>
    </main>
  );
};

export default ContentSidebar;
