import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { FiCheck } from 'react-icons/fi';

import Circle from 'components/Circle/Circle';
import ShoppingBag from 'components/Icons/Project/ShoppingBag';
import Image from 'components/Image';
import Price from 'components/Price/Price';

import CartStore from 'store/cart';

import * as styles from './Product.module.scss';

const Product = ({ product }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const addToCart = (e) => {
    e.preventDefault();
    CartStore.addCartItem({ ...product }, 1);
    setShowSuccess(true);
    // try {
    //   fbqEvent('track', 'AddToCart', {
    //     value: product.finalPrice / 100,
    //     currency: 'EUR',
    //     content_type: 'product',
    //     content_ids: product.sku,
    //   });
    // } catch (e) {
    //   console.log(e);
    // }
  };

  useEffect(() => {
    let mounted = true;
    if (showSuccess === true) {
      setTimeout(() => {
        mounted && setShowSuccess(false);
      }, 3000);
    }
    return () => {
      mounted = false;
    };
  }, [showSuccess]);

  return (
    <Link className={styles.link} to={product.relativePath || product.slug}>
      <div>
        <figure className={styles.figure}>
          <Image
            className={styles.imgContainer}
            filename={product.images[0].hash}
            alt={product.images[0].alternativeText || product.title}
          />
          {product.sticker && (
            <div className={styles.sticker}>{product.sticker}</div>
          )}
        </figure>
        <section className={styles.section}>
          <div>
            <h2 className={styles.categoryTitle}>
              {product.mainCategoryTitle}
            </h2>
            <h2 className={styles.title}>{product.title}</h2>
            <p className={styles.description}>{product.description}</p>
            <div className={styles.bottomSection}>
              <Price
                beginPrice={product.beginPrice}
                finalPrice={product.finalPrice}
              />
              <button
                className={styles.btn}
                aria-label="Add product to cart"
                data-testid="tile-add-to-cart"
                onClick={addToCart}
              >
                <Circle>
                  {showSuccess ? (
                    <IconContext.Provider
                      value={{ color: '#000', size: '1.75rem' }}
                    >
                      <FiCheck />
                    </IconContext.Provider>
                  ) : (
                    <ShoppingBag
                      style={{ marginTop: '-2px' }}
                      width="24px"
                      height="24px"
                      fill="#000"
                    />
                  )}
                </Circle>
              </button>
            </div>
          </div>
        </section>
      </div>
    </Link>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    relativePath: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    beginPrice: PropTypes.number.isRequired,
    finalPrice: PropTypes.number.isRequired,
    sticker: PropTypes.string,
    variants: PropTypes.array,
    description: PropTypes.string,
    mainCategoryTitle: PropTypes.string,
  }),
};

export default Product;
