import loadable from '@loadable/component';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { FaFilter } from 'react-icons/fa';
import Select from 'react-select';

import Container from 'components/Container/Container';
import ContentSidebar from 'components/ContentSidebar/ContentSidebar';
import Filters from 'components/Filters/Filters';
import Layout from 'components/Layout';
import ProductContainer from 'components/Product/ProductContainer';
import ProductTile from 'components/Product/ProductTile';
import Seo from 'components/Seo';
import Title from 'components/Title/Title';

import {
  hasChangedPrice,
  hasSelectedFilters,
  initialFilters,
  handleProducts,
  getPageCount,
  getOffset,
  handleFilters,
} from 'utilities/category';

import { linkUnderlined } from 'styles/components/links.module.scss';
import {
  breaker,
  container,
  li,
  linkClass,
  active,
} from 'styles/components/paginate.module.scss';
import * as styles from 'styles/pages/category.module.scss';

import 'styles/overrides/react-select.scss';

// temp fix for react-paginate
if (typeof window !== 'undefined') {
  window.global = window;
}
const ReactPaginate =
  typeof window !== 'undefined'
    ? loadable(() => import('react-paginate'))
    : null;

const options = [
  { value: 'default', label: 'Χωρίς ταξινόμηση' },
  { value: 'asc', label: 'Αύξουσα τιμή' },
  { value: 'desc', label: 'Φθίνουσα τιμή' },
];

export default function CategoryPage({
  data: { strapiCategory: category },
  pageContext: { products: allProducts, filteredProducts, filters },
}) {
  const { min, max } = filters;
  const numberPerPage = 12;

  const [selectedFilters, setSelectedFilters] = React.useState(initialFilters);
  const [sorting, setSorting] = React.useState(options[0]);
  const [price, setPrice] = React.useState([min, max]);
  const [offsetUser, setOffsetUser] = React.useState(0);

  const data = handleProducts(
    filteredProducts,
    allProducts,
    selectedFilters,
    price,
    min,
    max,
    sorting.value
  );

  const pageCount = getPageCount(data.length, numberPerPage);
  const offset = getOffset(data.length, offsetUser, numberPerPage, pageCount);
  const currentData = data.slice(offset, offset + numberPerPage);
  const currentPage = offset / numberPerPage;

  const heroImage =
    category && category.localFile && category.localFile[0]
      ? getImage(category.localFile[0])
      : null;

  const resetPrice = () => setPrice([min, max]);
  const handleFilter = (filter, type) => {
    setSelectedFilters(handleFilters(selectedFilters, filter, type));
  };
  const handleReset = () => {
    setSelectedFilters(initialFilters);
    resetPrice();
  };
  const handleSorting = (selectedOption) => {
    setSorting(selectedOption);
  };
  const handleSlider = (change) => {
    setPrice([...change]);
  };
  const handlePageClick = (event) => {
    const selected = event.selected;
    setOffsetUser(Math.ceil(selected * numberPerPage));
  };

  return (
    <Layout fullWidth={true}>
      <Seo
        title={`${category.title} | ${
          category.strapiParent && ' | ' + category.strapiParent.title
        }`}
        description={`Δείτε ${category.title} ${
          category.strapiParent && ' | ' + category.strapiParent.title
        } σε μοναδικές τιμές από το ηλεκτρονικό κατάστημα Minoera Traditional Cretan Jewelry για γρήγορη και εύκολη αγορά.`}
        meta={
          currentData.length > 0 && [
            ...currentData[0].images.map((image) => {
              return {
                property: 'og:image',
                content: `${process.env.GATSBY_API_URL}${image.url}`,
              };
            }),
            ...currentData[0].images.map((image) => {
              return {
                property: 'twitter:image',
                content: `${process.env.GATSBY_API_URL}${image.url}`,
              };
            }),
          ]
        }
      />
      <div className={styles.banner}>
        {heroImage ? (
          <GatsbyImage image={heroImage} className={styles.hero} alt="" />
        ) : (
          <StaticImage
            layout="fullWidth"
            alt=""
            src={'../images/category.jpg'}
            formats={['auto', 'webp', 'avif']}
            className={styles.hero}
          />
        )}
        <div className={styles.bannerContent}>
          <Title isBanner={true}>{category.title}</Title>
          <div className={styles.breadcrumbs}>
            <Link className={`${linkUnderlined} ${styles.link}`} to="/">
              ΑΡΧΙΚΗ
            </Link>
            <span className={styles.dot}>&middot;</span>
            {category.strapiParent && category.strapiParent.slug && (
              <>
                <Link
                  className={`${linkUnderlined} ${styles.link}`}
                  to={`/${category.strapiParent.slug}`}
                >
                  {category.strapiParent.title}
                </Link>
                <span className={styles.dot}>&middot;</span>
              </>
            )}
            <Link
              className={`${linkUnderlined} ${styles.link} ${styles.active}`}
              to={`${
                category.strapiParent && category.strapiParent.slug
                  ? '/' + category.strapiParent.slug
                  : ''
              }/${category.slug}`}
            >
              {category.title}
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <Container>
          <ContentSidebar
            sidebar={
              (data.length > 0 ||
                hasSelectedFilters(selectedFilters) ||
                hasChangedPrice(price, min, max)) && (
                <Filters
                  handleReset={handleReset}
                  handleFilter={handleFilter}
                  handleSlider={handleSlider}
                  filters={filters}
                  filteredProducts={filteredProducts}
                  selectedFilters={selectedFilters}
                  hasSelectedFilters={hasSelectedFilters(selectedFilters)}
                  hasChangedPrice={hasChangedPrice(price, min, max)}
                  price={price}
                  resetPrice={resetPrice}
                />
              )
            }
            icon={<FaFilter />}
            content={
              <>
                {data.length > 0 && (
                  <div className={styles.subheaderContainer}>
                    <p>
                      {data.length === 1
                        ? `1 προϊόν`
                        : `${data.length} προϊόντα`}
                    </p>
                    <Select
                      className={styles.control}
                      classNamePrefix="react-select"
                      value={sorting}
                      label="Ταξινόμηση"
                      placeholder="Ταξινόμηση"
                      onChange={handleSorting}
                      options={options}
                      isSearchable={false}
                    />
                  </div>
                )}
                <ProductContainer>
                  {currentData.length > 0 ? (
                    currentData.map((product) => (
                      <ProductTile key={product.id} product={product} />
                    ))
                  ) : (
                    <p style={{ margin: '2rem 0' }}>
                      Δεν υπάρχουν διαθέσιμα προϊόντα…
                    </p>
                  )}
                </ProductContainer>
                {ReactPaginate && (
                  <ReactPaginate
                    previousLabel={false}
                    nextLabel={false}
                    breakLabel={'...'}
                    breakClassName={breaker}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={container}
                    pageClassName={li}
                    pageLinkClassName={linkClass}
                    activeClassName={active}
                    forcePage={currentPage}
                  />
                )}
              </>
            }
          />
          <br />
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query SingleCategory($id: String!) {
    strapiCategory(id: { eq: $id }) {
      title
      slug
      strapiParent {
        slug
        title
      }
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;
