import React from 'react';

import Product from 'components/Product/Product';

import * as styles from './Product.module.scss';

const ProductTile = ({ product }) => {
  return (
    <li className={styles.outerContainer} data-testid="product-tile">
      <Product product={product} />
    </li>
  );
};

export default ProductTile;
