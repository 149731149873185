import React from 'react';

import {
  filter as filterClass,
  selected,
  text,
} from 'components/FilterButton/FilterButton.module.scss';

const FilterButton = ({
  filter,
  items,
  handleClick,
  isSelected,
  isDisabled = null,
}) => {
  return (
    <button
      type="button"
      key={filter}
      aria-label={filter}
      onClick={handleClick}
      disabled={isDisabled}
      className={`${filterClass} ${isSelected && selected}`}
    >
      <span className={text}>{filter}</span>
      <span>{items}</span>
    </button>
  );
};

export default FilterButton;
