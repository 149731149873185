import React from 'react';

import * as styles from './Title.module.scss';

const Title = ({ children, isBanner = false, ...rest }) => {
  return (
    <h1 className={isBanner ? styles.bannerTitle : styles.title} {...rest}>
      {children}
    </h1>
  );
};

export default Title;
