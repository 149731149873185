// extracted by mini-css-extract-plugin
export var container = "Filters-module--container--rX1Pw";
export var sectionTitle = "Filters-module--sectionTitle--1_2W2";
export var innerContainer = "Filters-module--innerContainer--3lx14";
export var selectedContainer = "Filters-module--selected-container--HlbA5";
export var selectedInnerContainer = "Filters-module--selected-inner-container--S6DG_";
export var tagContainer = "Filters-module--tag-container--2iiZa";
export var clearBtn = "Filters-module--clearBtn--31SJE";
export var filterContainer = "Filters-module--filter-container--1tpTr";
export var title = "Filters-module--title--2w9oT";
export var itemsContainer = "Filters-module--items-container--2HMri";
export var priceContainer = "Filters-module--price-container--3zmCQ";
export var sliderContainer = "Filters-module--sliderContainer--3xPN3";
export var range = "Filters-module--range--2yuzZ";