import React from 'react';

import { price, finalPrice as fp, beginPrice as bp } from './Price.module.scss';

const Price = ({ finalPrice, beginPrice, fpClass = '', bpClass = '' }) => {
  return (
    <p className={price}>
      <span className={`${fp}${fpClass ? ' ' + fpClass : ''}`}>
        {(finalPrice / 100).toFixed(2)} €
      </span>
      {beginPrice && beginPrice !== finalPrice && (
        <span className={`${bp}${bpClass ? ' ' + bpClass : ''}`}>
          {(beginPrice / 100).toFixed(2)} €
        </span>
      )}
    </p>
  );
};

export default Price;
