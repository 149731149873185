import React from 'react';
import { IconContext } from 'react-icons';
import { FiX } from 'react-icons/fi';

import * as styles from 'components/Tag/Tag.module.scss';

const Tag = ({ text, action }) => {
  return (
    <div className={styles.tag}>
      <span>{text}</span>
      <button onClick={action} className={styles.tagBtn}>
        <IconContext.Provider value={{ size: '20px' }}>
          <FiX />
        </IconContext.Provider>
      </button>
    </div>
  );
};

export default Tag;
