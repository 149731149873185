import { Range } from 'rc-slider';
import React from 'react';
import { FiTrash2, FiSliders } from 'react-icons/fi';

import FilterButton from 'components/FilterButton/FilterButton';
import Tag from 'components/Tag/Tag';

import * as styles from './Filters.module.scss';

import 'styles/overrides/rc-slider.scss';

const Filters = ({
  filters,
  selectedFilters,
  filteredProducts,
  handleReset,
  handleFilter,
  hasSelectedFilters,
  hasChangedPrice,
  handleSlider,
  price,
  resetPrice,
}) => {
  return (
    <section className={styles.container}>
      <h2 className={styles.sectionTitle}>
        <FiSliders
          style={{
            marginRight: '0.5rem',
          }}
        />
        Φίλτρα
      </h2>
      <div className={styles.innerContainer}>
        {(hasSelectedFilters || hasChangedPrice) && (
          <div className={styles.selectedContainer}>
            <h2 className={styles.title}>Επιλεγμένα</h2>
            <div className={styles.tagContainer}>
              {selectedFilters &&
                selectedFilters.kind &&
                Object.keys(selectedFilters.kind).map((key) =>
                  selectedFilters.kind[key] ? (
                    <Tag
                      key={key}
                      text={key}
                      action={() => {
                        handleFilter(key, 'kind');
                      }}
                    />
                  ) : null
                )}
              {selectedFilters &&
                selectedFilters.material &&
                Object.keys(selectedFilters.material).map((key) =>
                  selectedFilters.material[key] ? (
                    <Tag
                      key={key}
                      text={key}
                      action={() => {
                        handleFilter(key, 'material');
                      }}
                    />
                  ) : null
                )}
              {hasChangedPrice && (
                <Tag
                  text={
                    <>
                      Τιμή: {(price[0] / 100).toFixed(2)}€ -{' '}
                      {(price[1] / 100).toFixed(2)}€
                    </>
                  }
                  action={resetPrice}
                />
              )}
            </div>
            <div className={styles.selectedInnerContainer}>
              <button
                type="button"
                aria-label="Clear filters"
                className={styles.clearBtn}
                onClick={handleReset}
              >
                <FiTrash2
                  style={{
                    marginRight: '0.5rem',
                    fontSize: '20px',
                    fontWeight: 'normal',
                  }}
                />
                Καθαρισμός φίλτρων
              </button>
            </div>
          </div>
        )}
        {filters.kind && filters.kind.length > 0 && (
          <>
            <div className={styles.filterContainer}>
              <h2 className={styles.title}>Τύπος</h2>
              <div className={styles.itemsContainer}>
                {filters.kind.map((kind) => (
                  <FilterButton
                    key={kind}
                    filter={kind}
                    aria-label={kind}
                    items={filteredProducts[kind].length}
                    handleClick={() => {
                      handleFilter(kind, 'kind');
                    }}
                    isSelected={!!selectedFilters.kind[kind]}
                  />
                ))}
              </div>
            </div>
            <hr />
          </>
        )}
        {filters.material && filters.material.length > 0 && (
          <>
            <div className={styles.filterContainer}>
              <h2 className={styles.title}>Υλικό</h2>
              <div className={styles.itemsContainer}>
                {filters.material.map((material) => (
                  <FilterButton
                    key={material}
                    filter={material}
                    aria-label={material}
                    items={filteredProducts[material].length}
                    handleClick={() => {
                      handleFilter(material, 'material');
                    }}
                    isSelected={!!selectedFilters.material[material]}
                  />
                ))}
              </div>
            </div>
            <hr />
          </>
        )}
        {filters.min && filters.max && (
          <>
            <div className={styles.filterContainer}>
              <h2 className={styles.title}>Τιμή</h2>
              <div className={styles.itemsContainer}>
                <div className={styles.sliderContainer}>
                  <Range
                    allowCross={false}
                    min={filters.min}
                    max={filters.max}
                    defaultValue={[filters.min, filters.max]}
                    onChange={handleSlider}
                    value={price}
                    step={filters.step}
                  />
                </div>
                <p className={styles.priceContainer}>
                  <span className={styles.range}>
                    {(price[0] / 100).toFixed(2)}€ -{' '}
                    {(price[1] / 100).toFixed(2)}€
                  </span>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Filters;
