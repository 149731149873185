// extracted by mini-css-extract-plugin
export var banner = "category-module--banner--1m4nW";
export var hero = "category-module--hero--3wyqm";
export var bannerContent = "category-module--banner-content--1sjJC";
export var subheaderContainer = "category-module--subheader-container--2xIBj";
export var control = "category-module--control--33m6P";
export var breadcrumbs = "category-module--breadcrumbs--1Pihh";
export var link = "category-module--link--1tZ5s";
export var active = "category-module--active--19BPB";
export var dot = "category-module--dot--39s5w";
export var container = "category-module--container--2CYSF";