import React from 'react';

import * as styles from './ProductContainer.module.scss';

const ProductContainer = ({ children, fit = null }) => {
  return (
    <ul className={fit ? styles.containerFit : styles.container}>{children}</ul>
  );
};

export default ProductContainer;
